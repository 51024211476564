@font-face {
    font-family: "Poppins-Black";
    src: url(assets/fonts/Poppins/Poppins-Black.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-BlackItalic";
    src: url(assets/fonts/Poppins/Poppins-BlackItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Bold";
    src: url(assets/fonts/Poppins/Poppins-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-BoldItalic";
    src: url(assets/fonts/Poppins/Poppins-BoldItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-ExtraBold";
    src: url(assets/fonts/Poppins/Poppins-ExtraBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-ExtraBoldItalic";
    src: url(assets/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-ExtraLight";
    src: url(assets/fonts/Poppins/Poppins-ExtraLight.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-ExtraLightItalic";
    src: url(assets/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Italic";
    src: url(assets/fonts/Poppins/Poppins-Italic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Light";
    src: url(assets/fonts/Poppins/Poppins-Light.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-LightItalic";
    src: url(assets/fonts/Poppins/Poppins-LightItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Medium";
    src: url(assets/fonts/Poppins/Poppins-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-MediumItalic";
    src: url(assets/fonts/Poppins/Poppins-MediumItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Regular";
    src: url(assets/fonts/Poppins/Poppins-Regular.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-SemiBold";
    src: url(assets/fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-SemiBoldItalic";
    src: url(assets/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-Thin";
    src: url(assets/fonts/Poppins/Poppins-Thin.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Poppins-ThinItalic";
    src: url(assets/fonts/Poppins/Poppins-ThinItalic.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "DINAlternate-Bold";
    src: url(assets/fonts/DIN-Alternate/DINAlternate-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Archivo-Bold";
    src: url(assets/fonts/Archivo/Archivo-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Inter-Medium";
    src: url(assets/fonts/Inter/Inter-Medium.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "DrukWide-Bold";
    src: url(assets/fonts/DrukWide/DrukWide-Bold.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Gamer";
    src: url(assets/fonts/Gamer/Gamer.ttf) format('truetype');
    font-display: swap;
}

@font-face {
    font-family: "Sofia-Pro";
    src: url(assets/fonts/Sofia-Pro/Sofia-Pro-Regular-Az.otf) format('opentype');
    font-display: swap;
}